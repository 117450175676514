import classNames from 'classnames';

export interface InputCheckBoxProps {
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  id?: string;
  value?: string;
  size?: 'sm' | 'md';
  onChange?: (on: boolean) => void;
}

export function InputCheckboxWrapper({ children, label }: { children: React.ReactNode; label: string }) {
  return (
    <div className="flex items-center space-x-2">
      {children}
      <p className="text-sm">{label}</p>
    </div>
  );
}

export default function InputCheckbox(props: InputCheckBoxProps) {
  return (
    <>
      <input
        id={props.id}
        type="checkbox"
        value={props.checked ? 'true' : 'false'}
        className={classNames('rounded border-gray-300 text-black focus:ring-black', {
          'cursor-pointer': !props.disabled,
          'cursor-not-allowed': props.disabled,
          'size-4': !props.size || props.size === 'sm',
          'size-6': !props.size || props.size === 'md',
        })}
        checked={props.checked}
        disabled={props.disabled}
        onChange={(e) => {
          props.onChange && props.onChange(e.target.checked);
        }}
      />
      {props.name && (
        <input
          type="hidden"
          name={props.name}
          value={typeof props.value === 'undefined' ? (props.checked ? 'true' : 'false') : props.value}
        />
      )}
    </>
  );
}
